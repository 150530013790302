import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

const Image = ({ src, alt, sizes, ...rest }) => {
  const images = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(
            jpegProgressive: true
            jpegQuality: 50
            pngQuality: 50
            maxWidth: 1200
          ) {
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);
  const srcSplit = src.split('/');
  const foundImage = images.allImageSharp.nodes.find((i) => {
    const current = i.fluid.src.split('/');

    return current[current.length - 1] === srcSplit[srcSplit.length - 1];
  });

  return foundImage ? (
    <img
      sizes={sizes || foundImage.fluid.sizes}
      srcSet={foundImage.fluid.srcSet}
      src={foundImage.fluid.src}
      alt={alt || 'facticular'}
      {...rest}
    />
  ) : (
    <></>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  sizes: PropTypes.string,
};

export default Image;
